import { Box, Center, Hidden, HStack, Text, VStack, Link } from "native-base";
import React, { useState } from "react";
import TNFDImage from "@src/images/tnfd.png";
import TNFDImageOne from "@src/images/tnfdone.jpg";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fontWeight } from "styled-system";
import ReactSelect from "react-select";
import tab_view from "../tab_view";

const FooterTNFD = () => {
  const history = useHistory();
  const moveToPage = (routes) => {
    history.push({
      pathname: `${routes}`,
    });
  };
  const languages = [
    { id: "e0d9e2a1-4d8a-4e1c-a623-5a558954893d", name: "தமிழ்", key: "en" },
    { id: "ab5a2300-a29c-442f-84d0-8b1bd4174500", name: "English", key: "tn" },
  ];
  let find_index = languages.findIndex(
    (item) =>
      item.id === JSON.parse(localStorage?.getItem("current_language"))?.id
  );

  const languageName = JSON.parse(
    localStorage.getItem("current_language")
  )?.key;

  const [language, setLanguage] = useState(
    languages[find_index > -1 ? find_index : 0]
  );
  const handleChangeLanguage = () => {
    setLanguage(languages[(index + 1) % 2]);
    setIndex((index + 1) % 2);
    localStorage.setItem(
      "current_language",
      JSON.stringify(languages[(index + 1) % 2])
    );
    changeLanguage(languages[(index + 1) % 2].key);
    window.location.reload();
  };
  const { t } = useTranslation();
  return (
    <Box
      style={{
        boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset",
        overflowX: "hidden",
      }}
    >
      <Hidden only={["xl", "md", "lg"]}>
        {languageName === "en" ? (
          <Box>
            <img
              className="logoheightfooter"
              src={TNFDImageOne}
              alt="Menu Image"
              style={{ overflowY: "hidden" ,height:"600px"}}
            />
          </Box>
        ) : (
          <Box>
            <img
              className="logoheightfootertn"
              src={TNFDImageOne}
              alt="Menu Image"
              style={{ overflowY: "hidden" ,height:"700px"}}
            />
          </Box>
        )}
      </Hidden>

      <Hidden only={["sm", "xs"]}>
        <Box>
          <img
            className="logoheightfooter"
            src={TNFDImage}
            alt="Menu Image"
            width="100%"
            height="400px"
            style={{ verflowY: "hidden" }}
          />
        </Box>
      </Hidden>
      <Box h="100%" w="100%" position="absolute" bg="black" opacity={0.5}></Box>
      <HStack
        position="absolute"
        space={10}
        h="100%"
        w="100%"
        flexDirection={{
          base: "column",
          md: "row",
        }}
      >
       
        <Box w="65%" justifyContent={"center"} marginLeft="30px">
          <Box h="40%" w="100%" justifyContent={"center"}>
            <Text
              marginTop={["100px", "-45px", "0px"]}
              marginLeft={["100px", "30px", "0px"]}
              color="#d7d7d7"
              fontSize={18}
              style={{ fontFamily: "Julius Sans One" }}
            >
              {t("Quick_Links")}
            </Text>
          </Box>
          <Hidden only={["sm", "xs"]}>
            <Box h="60%" w="100%">
              <VStack>
                <Box w="100%">
                  <HStack space={10}>
                    <Box w="20%">
                      <VStack space={5}>
                        <Box>
                          <Text
                            variant="footer_link"
                            color="#d7d7d7"
                            width={["0px", "90px", "120px"]}
                            onClick={() => {
                              moveToPage(ROUTES.PAPER_CLIPPINGS);
                            }}
                          >
                            {t("Paper_Clippings")}
                          </Text>
                        </Box>
                        <Box>
                          <Text
                            variant="footer_link"
                            color="#d7d7d7"
                            onClick={() => {
                              moveToPage(ROUTES.POLICY_NOTE);
                            }}
                          >
                            {t("project_programmes")}
                          </Text>
                        </Box>
                        <Box>
                          <Text
                            variant="footer_link"
                            onClick={() => {
                              moveToPage(ROUTES.ADMINISTRATION);
                            }}
                          >
                            {t("organization")}
                          </Text>
                        </Box>
                      </VStack>
                    </Box>
                    <Box w="20%">
                      <VStack space={5}>
                        <Box>
                          <Text
                            variant="footer_link"
                            onClick={() => {
                              moveToPage(ROUTES.POLICIES);
                            }}
                          >
                            {t("Legislations")}
                          </Text>
                        </Box>
                        <Box>
                          <Text
                            variant="footer_link"
                            onClick={() => {
                              moveToPage(
                                ROUTES.TAMIL_NADU_TEA_PLANTATION_CORPORATION_TANTEA_LIMITED_COONOOR
                              );
                            }}
                          >
                            {t("Corporation")}
                          </Text>
                        </Box>
                        {/* <Box>
                          <Text
                            variant="footer_link"
                            // onClick={() => {
                            //   moveToPage(ROUTES.TAMIL_NADU_BIODIVERSITY_BOARD);
                            // }}
                          >
                            {t("Boards")}
                          </Text>
                        </Box> */}
                        <Box>
                          <Text
                            variant="footer_link"
                            onClick={() => {
                              moveToPage(
                                ROUTES.INNOVATION_MUDUMALAI_TIGER_RESERVE
                              );
                            }}
                          >
                            {t("Tiger_Reserves")}
                          </Text>
                        </Box>
                        
                      </VStack>
                    </Box>

                    <Box w="20%">
                      <VStack space={5}>
                        <Box>
                          <Text
                            variant="footer_link"
                            onClick={() => {
                              moveToPage(ROUTES.INNOVATION_DHARMAPURI);
                            }}
                          >
                            {t("innovations")}
                          </Text>
                        </Box>
                        <Box>
                          <Text
                            variant="footer_link"
                            onClick={() => {
                              moveToPage(ROUTES.BEST_PRACTICES);
                            }}
                          >
                            {t("Best_Practices")}
                          </Text>
                        </Box>
                        <Box>
                          <Text
                            variant="footer_link"
                            onClick={() => {
                              moveToPage(ROUTES.RECENT_G_OS);
                            }}
                          >
                            {t("Recent_GO.s")}
                          </Text>
                        </Box>
                      </VStack>
                    </Box>
                    <Box w="20%">
                      <VStack space={5}>
                        <Box>
                          <Text
                            variant="footer_link"
                            onClick={() => {
                              moveToPage(ROUTES.INTERNATIONAL_PUBLICATIONS);
                            }}
                          >
                            {t("Publications")}
                          </Text>
                        </Box>
                        {/* <Box>
                          <Text
                            variant="footer_link"
                            // onClick={() => {
                            //   moveToPage(ROUTES.BEST_PRACTICES);
                            // }}
                          >
                            {t("corporations")}
                          </Text>
                        </Box> */}
                        <Box>
                          <Text
                            variant="footer_link"
                            onClick={() => {
                              moveToPage(ROUTES.BOARDMISSION);
                            }}
                          >
                            {t("boards_mission")}
                          </Text>
                        </Box>
                        <Box>
                          <Text
                            variant="footer_link"
                            onClick={() => {
                              moveToPage(ROUTES.SANCTUARY);
                            }}
                          >
                            {t("sanctuaries")}
                          </Text>
                        </Box>
                      </VStack>
                    </Box>
                    <Box w="20%">
                      <VStack space={5}>
                        <Box>
                          <Text
                            variant="footer_link"
                            onClick={() => {
                              moveToPage(ROUTES.NATIONAL_PARKS);
                            }}
                          >
                            {t("national_parks")}
                          </Text>
                        </Box>
                        <Box>
                          <Text
                            variant="footer_link"
                            onClick={() => {
                              moveToPage(ROUTES.BEST_PRACTICES);
                            }}
                          >
                            {t("reserve_forest_reserve_lands")}
                          </Text>
                        </Box>

                        <Box>
                          <Text
                            variant="footer_link"
                            onClick={() => {
                              moveToPage(ROUTES.TENDERS_ADVERTISEMENTS);
                            }}
                          >
                            {t("Tenders")}
                          </Text>
                        </Box>
                        {/* <Box>
                          <Text
                            variant="footer_link"
                            // onClick={() => {
                            //   moveToPage(ROUTES.RECENT_G_OS);
                            // }}
                          >
                            {t("reserve_lands")}
                          </Text>
                        </Box> */}
                      </VStack>
                    </Box>
                    <Box w="20%" marginLeft="40px">
                      <VStack space={5}>
                        <Box marginTop={180}>
                          <Text variant="footer_link" width={"150px"}>
                            <a
                              target={"_blank"}
                              style={{
                                textdecoration: "none",
                                textDecorationColor: "none",
                                color: "white",
                              }}
                              href="https://vertace.com/"
                            >
                              Powered by
                            </a>
                            {"  "}
                            <Text
                              style={{
                                color: "#3C5FA8",
                                fontWeight: "800",
                                textdecoration: "none",
                                textDecorationColor: "none",
                              }}
                            >
                              <a
                                target={"_blank"}
                                style={{
                                  textdecoration: "none",
                                  textDecorationColor: "none",
                                }}
                                href="https://vertace.com/"
                              >
                                VERTACE
                              </a>
                            </Text>
                          </Text>
                        </Box>
                      </VStack>
                    </Box>
                  </HStack>
                </Box>
                <Box></Box>
              </VStack>
            </Box>
          </Hidden>

          <Hidden only={["md", "xl", "lg"]}>
            <Box h="60%" w="100%">
              <Box w="100%" marginTop={["0px", "10px", "0px"]}>
                <HStack space={10}>
                  <Box
                    w="50%"
                    marginLeft={["100px", "20px", "0px"]}
                    marginTop={["100px", "-60px", "0px"]}
                  >
                    <VStack space={5}>
                      <Box>
                        <Text
                          variant="footer_link"
                          color="#d7d7d7"
                          width={["0px", "90px", "120px"]}
                          onClick={() => {
                            moveToPage(ROUTES.PAPER_CLIPPINGS);
                          }}
                        >
                          {t("Paper_Clippings")}
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          variant="footer_link"
                          color="#d7d7d7"
                          onClick={() => {
                            moveToPage(ROUTES.POLICY_NOTE);
                          }}
                        >
                          {t("project_programmes")}
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          variant="footer_link"
                          onClick={() => {
                            moveToPage(ROUTES.ADMINISTRATION);
                          }}
                        >
                          {t("organization")}
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          variant="footer_link"
                          onClick={() => {
                            moveToPage(ROUTES.POLICIES);
                          }}
                        >
                          {t("Legislations")}
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          variant="footer_link"
                          onClick={() => {
                            moveToPage(
                              ROUTES.TAMIL_NADU_TEA_PLANTATION_CORPORATION_TANTEA_LIMITED_COONOOR
                            );
                          }}
                        >
                          {t("Corporation")}
                        </Text>
                      </Box>
                    
                      <Box>
                        <Text
                          variant="footer_link"
                          onClick={() => {
                            moveToPage(
                              ROUTES.INNOVATION_MUDUMALAI_TIGER_RESERVE
                            );
                          }}
                        >
                          {t("Tiger_Reserves")}
                        </Text>
                      </Box>
                      <Box>
                          <Text
                            variant="footer_link"
                            onClick={() => {
                              moveToPage(ROUTES.NATIONAL_PARKS);
                            }}
                          >
                            {t("national_parks")}
                          </Text>
                        </Box>
                    </VStack>
                  </Box>
                  <Box w="50%" marginTop={["100px", "-60px", "0px"]}>
                    <VStack space={5}>
                      {/* <Box>
                        <Text
                          variant="footer_link"
                          onClick={() => {
                            moveToPage(ROUTES.TAMIL_NADU_BIODIVERSITY_BOARD);
                          }}
                        >
                          {t("Biodiversity")}
                        </Text>
                      </Box> */}
                      {/* <Box>
                        <Text
                          variant="footer_link"
                          onClick={() => {
                            moveToPage(ROUTES.WILDLIFE);
                          }}
                        >
                          {t("Wildlife")}
                        </Text>
                      </Box> */}
                      <Box>
                        <Text
                          variant="footer_link"
                          onClick={() => {
                            moveToPage(ROUTES.TENDERS_ADVERTISEMENTS);
                          }}
                        >
                          {t("Tenders")}
                        </Text>
                      </Box>
                      <Box>
                          <Text
                            variant="footer_link"
                            onClick={() => {
                              moveToPage(ROUTES.BEST_PRACTICES);
                            }}
                          >
                            {t("reserve_forest_reserve_lands")}
                          </Text>
                        </Box>
                      <Box>
                        <Text
                          variant="footer_link"
                          onClick={() => {
                            moveToPage(ROUTES.INNOVATION_DHARMAPURI);
                          }}
                        >
                          {t("innovations")}
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          variant="footer_link"
                          onClick={() => {
                            moveToPage(ROUTES.BEST_PRACTICES);
                          }}
                        >
                          {t("Best_Practices")}
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          variant="footer_link"
                          onClick={() => {
                            moveToPage(ROUTES.RECENT_G_OS);
                          }}
                        >
                          {t("Recent_GO.s")}
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          variant="footer_link"
                          onClick={() => {
                            moveToPage(ROUTES.INTERNATIONAL_PUBLICATIONS);
                          }}
                        >
                          {t("Publications")}
                        </Text>
                        <Box>
                          <br/>
                          
                        <Box>
                          <Text
                            variant="footer_link"
                            onClick={() => {
                              moveToPage(ROUTES.BOARDMISSION);
                            }}
                          >
                            {t("boards_mission")}
                          </Text>
                        </Box>
                        <br/>
                        <Box>
                          <Text
                            variant="footer_link"
                            onClick={() => {
                              moveToPage(ROUTES.SANCTUARY);
                            }}
                          >
                            {t("sanctuaries")}
                          </Text>
                        </Box>    
                          <Text
                            variant="footer_link"
                            width={"120px"}
                            mt={"20px"}
                          >
                            <a
                              target="blank"
                              style={{
                                textdecoration: "none",
                                textDecorationColor: "none",
                                color: "white",
                              }}
                              href="https://vertace.com/"
                            >
                              Powered by
                            </a>
                            {"  "}
                            <Text
                              style={{
                                color: "#3C5FA8",
                                fontWeight: "800",
                                textdecoration: "none",
                                textDecorationColor: "none",
                                marginLeft: "13px",
                              }}
                            >
                              <a
                                target="blank"
                                style={{
                                  textdecoration: "none",
                                  textDecorationColor: "none",
                                }}
                                href="https://vertace.com/"
                              >
                                VERTACE
                              </a>
                            </Text>
                          </Text>
                        </Box>
                      </Box>
                    </VStack>
                  </Box>
                </HStack>
              </Box>
            </Box>
          </Hidden>
        </Box>
      </HStack>
    </Box>
  );
};
export default FooterTNFD;
